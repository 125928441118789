import React from 'react';

import Page from '../components/Page';
import SEO from '../components/seo';
import AboutNav from '../components/About/AboutNav';
import AboutUs from '../components/About/AboutUs';
import AboutTeam from '../components/About/AboutTeam';
import AboutFooter from '../components/About/AboutFooter';

function About() {
  return (
    <Page bgColour="#FF1200">
      <SEO keywords={[`gatsby`, `javascript`, `react`]} />

      <AboutNav />

      <AboutUs />

      <AboutTeam />

      <AboutFooter />
    </Page>
  );
}

export default About;
