import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

function AboutUs() {
  return (
    <div className={styles.aboutUs}>
      <h2 className={styles.heading}>About us two and the whole shebang...</h2>

      <div className={styles.bios}>
        <div className={classnames(styles.bio, styles.frankieBiscuit)}>
          <h3 className={styles.name}>Frankie Biscuit</h3>
          <p>
            He's the mastermind of twenty eight failed bank robberies and two
            successful nights at karaoke king. After an operation to create the
            perfect cyborg by installing bionic nipples, this man has more going
            for him than epilepsy on the dance floor and condoms for rabbits and
            yet he's happy with just devouring the tears you cry into your
            pillow each night we conveniently forget to reply good night. That's
            a hero for you.
          </p>
        </div>

        <div className={classnames(styles.bio, styles.mannaQuinn)}>
          <h3 className={styles.name}>Manna Quinn</h3>
          <p>
            She'll mow you down with her terrapin go-kart and her nazi
            propagandarific put downs. What exactly does that all adds up to? A
            papier mache frog wielding ninja? The pathetic leftovers from last
            nights risotto on the nose of a hippy? The eventual conclusion to
            the schoolyard dilemma of who will win between superman or yo momma?
            No one can say for sure... but we'll fear her by a good measure of
            superstition just like how the five second rule demands our respect!
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
