import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import styles from './styles.module.scss';

function AboutTeam() {
  return (
    <div className={styles.aboutTeam}>
      <p className={styles.intro}>
        <Link to="/">Scribbledwall</Link> is the collective collaboration
        between the two aforementioned day dreamers. Dreaming by day and night,
        between these two borderline crazies borderline geniuses, they have so
        many pent up ideas it's like the marriage of rabies with kiss and tell,
        a deadly combination in most holy books of the world. Luckily for you,
        they'll be spilling their special juices all over things you're going to
        want to hug. <Link to="/">Isn't life grand?</Link>
      </p>

      <h2 className={styles.heading}>
        <em>T</em>he <em>M</em>ain <em>O</em>ffenders
      </h2>

      <ul className={styles.bios}>
        <li
          className={classnames(styles.bio, styles.mannaQuinn)}
          id="manna-quinn"
        >
          <h3 className={styles.name}>
            Manna Quinn <br className={styles.nameBreak} />
            <small>AKA</small>{' '}
            <a href="/images/avatar-manna-real.jpg">Annam Tariq</a>
          </h3>
          <p className={styles.description}>
            She’s convinced she’s socially accepted but remains on the fringes
            of society
          </p>
          <p className={styles.backStory}>
            Artist and Make-up artist. Weird, eccentric and colourful, it’s
            reflected in her work. Studied media makeup, special effects, hair,
            beauty and high fashion styling. She’s been in makeup since
            September 2008 and has since taken it up professionally.
          </p>
          <div className={styles.avatar} />
        </li>

        <li
          className={classnames(styles.bio, styles.frankieBiscuit)}
          id="frankie-biscuit"
        >
          <h3 className={styles.name}>
            Frankie Biscuit <br className={styles.nameBreak} />
            <small>AKA</small>{' '}
            <a href="/images/avatar-frankie-real.jpg">Mike Ly</a>
          </h3>
          <p className={styles.description}>
            Socially retarded, professional pop culture referencing, wannabe
            hipster
          </p>
          <p className={styles.backStory}>
            All round Designer. Lovably awkward but constantly mocking. He’s
            almost always moronic, but he has his moments. His skills orientate
            towards design, in fact he designed and coded the site, but he’s
            eager and always willing to learn new skills!
          </p>
          <div className={styles.avatar} />
        </li>

        <li
          className={classnames(styles.bio, styles.cryptourniquet)}
          id="cryptourniquet"
        >
          <h3 className={styles.name}>
            Cryptourniquet <br className={styles.nameBreak} />
            <small>AKA</small>{' '}
            <a href="/images/avatar-cryptourniquet-real.jpg">
              Kelly-Marie Perry
            </a>
          </h3>
          <p className={styles.description}>
            Human encyclopedia of general knowledge and trivia. Rumoured to be
            the inspiration for Wikipedia.
          </p>
          <p className={styles.backStory}>
            Photographer, gamer, geek, cake fiend! Sarcastic serial daydreamer
            with a penchant for comedy. A part-time hypochondriac yet
            impressively she's been featured in a medical journal. Big space
            enthusiast and lover of all things starred and stripey, she's a girl
            of many trades with her eye on the deadly pathological arts. Beware
            or she'll add you to her huge plushie collection!
          </p>
          <div className={styles.avatar} />
        </li>
      </ul>

      <p className={styles.contact}>
        If you have a question or want to hire us out or just feel like telling
        us how much you love us, then you can{' '}
        <Link to="/contact">contact us here! ♥</Link>
      </p>
    </div>
  );
}

export default AboutTeam;
