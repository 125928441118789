import React from 'react';
import styles from './styles.module.scss';

function AboutFooter() {
  return (
    <div className={styles.aboutFooter}>
      <div className={styles.heart} />

      <p>
        Copyright 2009-{new Date().getFullYear()}.{' '}
        <a href="#manna-quinn">Manna Quinn</a> and{' '}
        <a href="#frankie-biscuit">Frankie Biscuit</a>. All rights reserved.
      </p>
    </div>
  );
}

export default AboutFooter;
