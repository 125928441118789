import React from 'react';
import { Link } from 'gatsby';
import styles from './styles.module.scss';

function AboutNav() {
  return (
    <div className={styles.nav}>
      <Link className={styles.navLink} to="/">
        Back
      </Link>
      <Link className={styles.heading} to="/">
        Scribbledwall
      </Link>
    </div>
  );
}

export default AboutNav;
